/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkUpdateTimesheetEntriesDataUpdateData } from './BulkUpdateTimesheetEntriesDataUpdateData';
import {
    BulkUpdateTimesheetEntriesDataUpdateDataFromJSON,
    BulkUpdateTimesheetEntriesDataUpdateDataFromJSONTyped,
    BulkUpdateTimesheetEntriesDataUpdateDataToJSON,
    BulkUpdateTimesheetEntriesDataUpdateDataToJSONTyped,
} from './BulkUpdateTimesheetEntriesDataUpdateData';

/**
 * 
 * @export
 * @interface BulkUpdateTimesheetEntriesData
 */
export interface BulkUpdateTimesheetEntriesData {
    /**
     * The ids of models to update
     * @type {Array<number>}
     * @memberof BulkUpdateTimesheetEntriesData
     */
    ids: Array<number>;
    /**
     * 
     * @type {BulkUpdateTimesheetEntriesDataUpdateData}
     * @memberof BulkUpdateTimesheetEntriesData
     */
    updateData: BulkUpdateTimesheetEntriesDataUpdateData;
}

/**
 * Check if a given object implements the BulkUpdateTimesheetEntriesData interface.
 */
export function instanceOfBulkUpdateTimesheetEntriesData(value: object): value is BulkUpdateTimesheetEntriesData {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    if (!('updateData' in value) || value['updateData'] === undefined) return false;
    return true;
}

export function BulkUpdateTimesheetEntriesDataFromJSON(json: any): BulkUpdateTimesheetEntriesData {
    return BulkUpdateTimesheetEntriesDataFromJSONTyped(json, false);
}

export function BulkUpdateTimesheetEntriesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateTimesheetEntriesData {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'updateData': BulkUpdateTimesheetEntriesDataUpdateDataFromJSON(json['updateData']),
    };
}

  export function BulkUpdateTimesheetEntriesDataToJSON(json: any): BulkUpdateTimesheetEntriesData {
      return BulkUpdateTimesheetEntriesDataToJSONTyped(json, false);
  }

  export function BulkUpdateTimesheetEntriesDataToJSONTyped(value?: BulkUpdateTimesheetEntriesData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ids': value['ids'],
        'updateData': BulkUpdateTimesheetEntriesDataUpdateDataToJSON(value['updateData']),
    };
}

