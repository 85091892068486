/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateTimesheetEntryDataBreaksInner } from './UpdateTimesheetEntryDataBreaksInner';
import {
    UpdateTimesheetEntryDataBreaksInnerFromJSON,
    UpdateTimesheetEntryDataBreaksInnerFromJSONTyped,
    UpdateTimesheetEntryDataBreaksInnerToJSON,
    UpdateTimesheetEntryDataBreaksInnerToJSONTyped,
} from './UpdateTimesheetEntryDataBreaksInner';

/**
 * 
 * @export
 * @interface UpdateTimesheetEntryData
 */
export interface UpdateTimesheetEntryData {
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryData
     */
    employeeId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryData
     */
    jobRoleId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryData
     */
    locationId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryData
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryData
     */
    endedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryData
     */
    confirmedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryData
     */
    confirmedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTimesheetEntryData
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateTimesheetEntryData
     */
    tags?: Array<number> | null;
    /**
     * 
     * @type {Array<UpdateTimesheetEntryDataBreaksInner>}
     * @memberof UpdateTimesheetEntryData
     */
    breaks?: Array<UpdateTimesheetEntryDataBreaksInner>;
}

/**
 * Check if a given object implements the UpdateTimesheetEntryData interface.
 */
export function instanceOfUpdateTimesheetEntryData(value: object): value is UpdateTimesheetEntryData {
    return true;
}

export function UpdateTimesheetEntryDataFromJSON(json: any): UpdateTimesheetEntryData {
    return UpdateTimesheetEntryDataFromJSONTyped(json, false);
}

export function UpdateTimesheetEntryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTimesheetEntryData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'startedAt': json['startedAt'] == null ? undefined : (new Date(json['startedAt'])),
        'endedAt': json['endedAt'] == null ? undefined : (new Date(json['endedAt'])),
        'confirmedAt': json['confirmedAt'] == null ? undefined : (new Date(json['confirmedAt'])),
        'confirmedBy': json['confirmedBy'] == null ? undefined : json['confirmedBy'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'breaks': json['breaks'] == null ? undefined : ((json['breaks'] as Array<any>).map(UpdateTimesheetEntryDataBreaksInnerFromJSON)),
    };
}

  export function UpdateTimesheetEntryDataToJSON(json: any): UpdateTimesheetEntryData {
      return UpdateTimesheetEntryDataToJSONTyped(json, false);
  }

  export function UpdateTimesheetEntryDataToJSONTyped(value?: UpdateTimesheetEntryData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'jobRoleId': value['jobRoleId'],
        'locationId': value['locationId'],
        'startedAt': value['startedAt'] == null ? undefined : ((value['startedAt']).toISOString()),
        'endedAt': value['endedAt'] == null ? undefined : ((value['endedAt']).toISOString()),
        'confirmedAt': value['confirmedAt'] == null ? undefined : ((value['confirmedAt']).toISOString()),
        'confirmedBy': value['confirmedBy'],
        'notes': value['notes'],
        'tags': value['tags'],
        'breaks': value['breaks'] == null ? undefined : ((value['breaks'] as Array<any>).map(UpdateTimesheetEntryDataBreaksInnerToJSON)),
    };
}

