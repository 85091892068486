/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpdateTimesheetEntriesDataUpdateData
 */
export interface BulkUpdateTimesheetEntriesDataUpdateData {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateTimesheetEntriesDataUpdateData
     */
    confirmed?: boolean;
}

/**
 * Check if a given object implements the BulkUpdateTimesheetEntriesDataUpdateData interface.
 */
export function instanceOfBulkUpdateTimesheetEntriesDataUpdateData(value: object): value is BulkUpdateTimesheetEntriesDataUpdateData {
    return true;
}

export function BulkUpdateTimesheetEntriesDataUpdateDataFromJSON(json: any): BulkUpdateTimesheetEntriesDataUpdateData {
    return BulkUpdateTimesheetEntriesDataUpdateDataFromJSONTyped(json, false);
}

export function BulkUpdateTimesheetEntriesDataUpdateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateTimesheetEntriesDataUpdateData {
    if (json == null) {
        return json;
    }
    return {
        
        'confirmed': json['confirmed'] == null ? undefined : json['confirmed'],
    };
}

  export function BulkUpdateTimesheetEntriesDataUpdateDataToJSON(json: any): BulkUpdateTimesheetEntriesDataUpdateData {
      return BulkUpdateTimesheetEntriesDataUpdateDataToJSONTyped(json, false);
  }

  export function BulkUpdateTimesheetEntriesDataUpdateDataToJSONTyped(value?: BulkUpdateTimesheetEntriesDataUpdateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'confirmed': value['confirmed'],
    };
}

