/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkUpdateEmployeesDataUpdateData } from './BulkUpdateEmployeesDataUpdateData';
import {
    BulkUpdateEmployeesDataUpdateDataFromJSON,
    BulkUpdateEmployeesDataUpdateDataFromJSONTyped,
    BulkUpdateEmployeesDataUpdateDataToJSON,
    BulkUpdateEmployeesDataUpdateDataToJSONTyped,
} from './BulkUpdateEmployeesDataUpdateData';

/**
 * 
 * @export
 * @interface BulkUpdateEmployeesData
 */
export interface BulkUpdateEmployeesData {
    /**
     * The ids of models to update
     * @type {Array<number>}
     * @memberof BulkUpdateEmployeesData
     */
    ids: Array<number>;
    /**
     * 
     * @type {BulkUpdateEmployeesDataUpdateData}
     * @memberof BulkUpdateEmployeesData
     */
    updateData: BulkUpdateEmployeesDataUpdateData;
}

/**
 * Check if a given object implements the BulkUpdateEmployeesData interface.
 */
export function instanceOfBulkUpdateEmployeesData(value: object): value is BulkUpdateEmployeesData {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    if (!('updateData' in value) || value['updateData'] === undefined) return false;
    return true;
}

export function BulkUpdateEmployeesDataFromJSON(json: any): BulkUpdateEmployeesData {
    return BulkUpdateEmployeesDataFromJSONTyped(json, false);
}

export function BulkUpdateEmployeesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateEmployeesData {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'updateData': BulkUpdateEmployeesDataUpdateDataFromJSON(json['updateData']),
    };
}

  export function BulkUpdateEmployeesDataToJSON(json: any): BulkUpdateEmployeesData {
      return BulkUpdateEmployeesDataToJSONTyped(json, false);
  }

  export function BulkUpdateEmployeesDataToJSONTyped(value?: BulkUpdateEmployeesData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ids': value['ids'],
        'updateData': BulkUpdateEmployeesDataUpdateDataToJSON(value['updateData']),
    };
}

