/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLocationData
 */
export interface UpdateLocationData {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationData
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationData
     */
    longitude?: number | null;
    /**
     * Whether this location is enabled for clocking events.
     * @type {boolean}
     * @memberof UpdateLocationData
     */
    enableClockIn?: boolean;
    /**
     * Controls whether this location can be used for clocking events from the main Shiftie application.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {boolean}
     * @memberof UpdateLocationData
     */
    allowClockInFromPersonalDevices?: boolean;
    /**
     * Controls whether this location can be used for clocking events from a Clock-In Portal.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {boolean}
     * @memberof UpdateLocationData
     */
    allowClockInFromPortals?: boolean;
    /**
     * Controls the maximum distance in meters an employee must be from a given location's geolocation to record clocking events.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {number}
     * @memberof UpdateLocationData
     */
    clockInGeoFenceInMeters?: number | null;
    /**
     * Can an employee clock in outside of the radius?
     * @type {boolean}
     * @memberof UpdateLocationData
     */
    allowClockInsOutsideOfRadius?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationData
     */
    status?: UpdateLocationDataStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateLocationDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateLocationData interface.
 */
export function instanceOfUpdateLocationData(value: object): value is UpdateLocationData {
    return true;
}

export function UpdateLocationDataFromJSON(json: any): UpdateLocationData {
    return UpdateLocationDataFromJSONTyped(json, false);
}

export function UpdateLocationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLocationData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'enableClockIn': json['enableClockIn'] == null ? undefined : json['enableClockIn'],
        'allowClockInFromPersonalDevices': json['allowClockInFromPersonalDevices'] == null ? undefined : json['allowClockInFromPersonalDevices'],
        'allowClockInFromPortals': json['allowClockInFromPortals'] == null ? undefined : json['allowClockInFromPortals'],
        'clockInGeoFenceInMeters': json['clockInGeoFenceInMeters'] == null ? undefined : json['clockInGeoFenceInMeters'],
        'allowClockInsOutsideOfRadius': json['allowClockInsOutsideOfRadius'] == null ? undefined : json['allowClockInsOutsideOfRadius'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

  export function UpdateLocationDataToJSON(json: any): UpdateLocationData {
      return UpdateLocationDataToJSONTyped(json, false);
  }

  export function UpdateLocationDataToJSONTyped(value?: UpdateLocationData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'enableClockIn': value['enableClockIn'],
        'allowClockInFromPersonalDevices': value['allowClockInFromPersonalDevices'],
        'allowClockInFromPortals': value['allowClockInFromPortals'],
        'clockInGeoFenceInMeters': value['clockInGeoFenceInMeters'],
        'allowClockInsOutsideOfRadius': value['allowClockInsOutsideOfRadius'],
        'status': value['status'],
    };
}

