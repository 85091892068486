/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A specific site, office, or workplace that a company has
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    status: LocationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    latitude: number | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    longitude: number | null;
    /**
     * Whether this location is enabled for clocking events.
     * @type {boolean}
     * @memberof Location
     */
    enableClockIn: boolean;
    /**
     * Controls whether this location can be used for clocking events from the main Shiftie application.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {boolean}
     * @memberof Location
     */
    allowClockInFromPersonalDevices: boolean;
    /**
     * Controls whether this location can be used for clocking events from a Clock-In Portal.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {boolean}
     * @memberof Location
     */
    allowClockInFromPortals: boolean;
    /**
     * Controls the maximum distance in meters an employee must be from a given location's geolocation to record clocking events.<br><small>**Note:** this is only used by our first party applications and is ignored for direct API requests.</small>
     * @type {number}
     * @memberof Location
     */
    clockInGeoFenceInMeters: number | null;
    /**
     * Can an employee clock in outside of the radius?
     * @type {boolean}
     * @memberof Location
     */
    allowClockInsOutsideOfRadius?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum LocationStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): value is Location {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('enableClockIn' in value) || value['enableClockIn'] === undefined) return false;
    if (!('allowClockInFromPersonalDevices' in value) || value['allowClockInFromPersonalDevices'] === undefined) return false;
    if (!('allowClockInFromPortals' in value) || value['allowClockInFromPortals'] === undefined) return false;
    if (!('clockInGeoFenceInMeters' in value) || value['clockInGeoFenceInMeters'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'status': json['status'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'enableClockIn': json['enableClockIn'],
        'allowClockInFromPersonalDevices': json['allowClockInFromPersonalDevices'],
        'allowClockInFromPortals': json['allowClockInFromPortals'],
        'clockInGeoFenceInMeters': json['clockInGeoFenceInMeters'],
        'allowClockInsOutsideOfRadius': json['allowClockInsOutsideOfRadius'] == null ? undefined : json['allowClockInsOutsideOfRadius'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function LocationToJSON(json: any): Location {
      return LocationToJSONTyped(json, false);
  }

  export function LocationToJSONTyped(value?: Location | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'status': value['status'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'enableClockIn': value['enableClockIn'],
        'allowClockInFromPersonalDevices': value['allowClockInFromPersonalDevices'],
        'allowClockInFromPortals': value['allowClockInFromPortals'],
        'clockInGeoFenceInMeters': value['clockInGeoFenceInMeters'],
        'allowClockInsOutsideOfRadius': value['allowClockInsOutsideOfRadius'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

